import "./App.css";
import GenshinPoster from "./assets/GenshinPoster.jpg";
import GenshinRoster from "./assets/GenshinRoster.jpg";
import GenshinApi from "./assets/GenshinApi.png";
import Character from "./assets/Character.png";
import Website from "./assets/Website.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function App() {
  return (
    <div className="App">
      <Navbar expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#introduction">
            <b>Development Project</b>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#introduction">Introduction</Nav.Link>
              <Nav.Link href="#preparation">Preparation</Nav.Link>
              <Nav.Link href="#usability">Usability</Nav.Link>
              <Nav.Link href="#components">Components</Nav.Link>
              <Nav.Link href="#states">States</Nav.Link>
              <Nav.Link href="#conclusion">Conclusion</Nav.Link>
              <Nav.Link href="https://luckylion777.github.io/project-5-development/">
                Final Product
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="parent">
        <section className="section" id="introduction">
          <Container>
            <p className="heading">Introduction</p>
            <p className="subheading">Project Description</p>
            <p>
              This CSCI 1300 project asks students to design and build a web
              application from scratch. They will need to develop an interactive
              interface, use interface components, and tie the components to an
              internal data state. Additionally, this interface must be
              list-based, meaning that it allows users to select items out of a
              list and then aggregates these choices.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={GenshinPoster}
                id="image"
                alt="An official release poster for Genshin Impact."
              ></img>
            </div>
            <p className="alt-text">
              An official release poster for Genshin Impact.
            </p>
            <br />
            <p className="subheading">Goal and Value of the Application</p>
            <p>
              Genshin Impact is a very popular game with over 60 million players
              and a plethora of awards. One of the main reasons players like me
              are so invested into the game is because of the amazing cast of
              characters, which are obtainable through a rotating gacha system.
              Therefore, the goal of my web application is to be a convenient
              tool for players to keep track of which characters they would like
              to obtain in the future. I think that this app can be valuable
              because it is a visual list where information about each character
              can be included, and I have not found any sites like this before.
              The reason why one can add multiple copies of a character to the
              wishlist is because duplicates in the game give massive upgrades
              to the character.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={GenshinRoster}
                id="image"
                alt="A poster showcasing the Genshin Impact roster."
              ></img>
            </div>
            <p className="alt-text">
              A poster showcasing the Genshin Impact roster.
            </p>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="preparation">
          <Container>
            <p className="heading">Preparation</p>
            <p className="subheading">Obtaining Game Data</p>
            <p>
              To get the in-game information for each character, I used{" "}
              <a href="https://genshin.dev/">this API</a> that the game
              community made. This was a really cool API because it included
              every single possible detail about each character, including their
              title, rarity, element, weapon, and nation. Moreover, using this
              API meant that my website would automatically be updated with
              every new character release. Since this is the first release of my
              application, the site could be improved upon in the future to
              include even more information about the characters, since I
              omitted a lot of it.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={GenshinApi}
                id="image"
                alt="A screenshot of the API website."
              ></img>
            </div>
            <p className="alt-text">A screenshot of the API website.</p>
            <br />
            <p>
              Once I found the right API, I used an Axios GET request to fetch
              the data for my own application. The request gave me a JSON for
              each character with fields I could access for their specific
              attributes. Notably, there was a bit of cleaning that had to be
              done to address some missing and unusable data.
            </p>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="usability">
          <Container>
            <p className="heading">Usability</p>
            <p className="subheading">Usability Principles Considered</p>
            <p>
              I wanted to make this application as easy to understand and
              convenient as possible, even for users who don't have too much
              experience with the game yet. Therefore, I focused on these design
              choices:
            </p>
            <ul>
              <li>
                The dropdown menus for sorting and filtering are at the top of
                the page.
              </li>
              <li>
                The sorting menu has a "None" option in italics so that the user
                knows how to reset the sort.
              </li>
              <li>
                The filtering menu has checkboxes so its easy to understand
                which filters are being applied.
              </li>
              <li>
                The filtering shows when multiple filters are being applied in
                the bar ("filter1, filter2, etc").
              </li>
              <li>
                The reset buttons for the sort/filter and wishlist pop out with
                a blue color.
              </li>
              <li>
                Each character card in the list has information presented in the
                same format, including an "add" button in blue and a "remove"
                button in gray so that a hierarchy can be maintained.
              </li>
              <li>
                Each character in the wishlist has an "add" button and "remove"
                button with the same design as those in the last bullet so that
                a hierarchy can be maintained.
              </li>
              <li>
                The wishlist is on the same page as the character list so the
                user can easily track what changes they are making.
              </li>
            </ul>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="components">
          <Container>
            <p className="heading">Components</p>
            <p className="subheading">Organization of Components</p>
            <p>
              For this application, I have made one generic component to
              represent a character card, with its code located in the
              Character.js file. These cards display information from the API
              about each character and include functions to add and remove them
              from the wishlist.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={Character}
                alt="An example of a character card in my application."
                style={{ marginTop: 15 }}
              ></img>
            </div>
            <p className="alt-text">
              An example of a character card in my application.
            </p>
            <br />
            <p className="subheading">
              How Data is Passed Down Through Components
            </p>
            <p>
              After I extract and parse information about each character (in the
              form of a JSON object) through useEffect() hooks, I map through
              this array of data and pass it through props into each character
              component. This data includes:
            </p>
            <ul>
              <li>
                The key of the character (for tracking them in the data arrays).
              </li>
              <li>
                The character's JSON object (for the components to extract
                display information from).
              </li>
              <li>
                The character's index adjusted for filters/sorts (the way I
                track how many of each character there are in the wishlist is
                through a state variable that is initialized to be full of
                zeroes, where each index of the array represents the amount of
                one character; therefore, adding one character to the wishlist
                adds 1 to the number in the array at that character's index).
              </li>
              <li>
                The state variables for the wishlist (character component has
                functions that affect the individual quantities in the
                wishlist).
              </li>
              <li>
                The state variables for the wishlist total (character component
                has functions that affect the total quantity of the wishlist).
              </li>
            </ul>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="states">
          <Container>
            <p className="heading">States</p>
            <p className="subheading">How State Changes are Triggered</p>
            <ul>
              <li>
                [names, setNames]: Array of names of the characters. This state
                is set in a useEffect() when getting data from the API. This
                state is referenced in a useEffect() when getting the character
                JSON objects from the API.
              </li>
              <li>
                [allData, setAllData]: Array of JSON objects of the characters
                to track the data without sorting or filtering. This state is
                set in a useEffect() when getting the objects from the API. This
                state is referenced in the useEffect() that handles
                sorting/filtering, as changing it should also changed the items
                being sorted. This state is referenced in the reset button's
                function so that the sorted/filtered data can go back to normal.
                This state is referenced in the wishlist's code because the
                items being added should be from the full array of characters,
                not one that has been sorted or filtered.
              </li>
              <li>
                [someData, setSomeData]: Array of JSON objects of the characters
                to track the data with sorting or filtering. This state is
                initialized to be equal to allData, but then changed in a
                useEffect() that handles sorting/filtering. Additionally, this
                useEffect() includes dependencies for when the filterMode,
                sortMode, or allData changes, which logically makes sense since
                this would require re-rendering in the app. It is reset back to
                allData in the reset button's function. This state is referenced
                in the character list's code so that the list changes based on
                which sorting or filtering actions were performed.
              </li>
              <li>
                [wishlist, setWishlist]: Array of numbers that represents how
                many of each character (the indices of the array) are in the
                wishlist. This state is initially set to be an array of zeroes.
                This state is referenced in the "reset wishlist", "add", and
                "remove" buttons' functions and changes the numbers at certain
                indexes accordingly.
              </li>
              <li>
                [total, setTotal]: Number that represents the total amount of
                stars/rarity (unit of accumulation) of the characters in the
                cart. This state is referenced in the "reset wishlist", "add",
                and "remove" buttons' functions and changes the total
                accordingly.
              </li>
              <li>
                [filterMode, setFilterMode]: Array of applied filter criteria,
                originally initialized to be empty. This state is set when the
                user selects options from the drop-down filter menu and reset
                when the user uses the reset sorting/filtering button. This
                state is referenced in a useEffect() that handles
                sorting/filtering.
              </li>
              <li>
                [sortMode, setSortMode]: String representing sort criteria,
                originally initialized to be an empty string. This state is set
                when the user selects options from the drop-down sort menu and
                reset when the user uses the reset sorting/filtering button.
                This state is referenced in a useEffect() that handles
                sorting/filtering.
              </li>
            </ul>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="conclusion">
          <Container>
            <p className="heading">Conclusion</p>
            <p className="subheading">Finished Product</p>
            <p>
              <a href="https://luckylion777.github.io/project-5-development/">
                Here
              </a>{" "}
              is the link to my application. As of now, it is not ready for
              mobile devices, but this is top priority for the next iteration.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={Website}
                id="image"
                alt="A screenshot of my finished website."
              ></img>
            </div>
            <p className="alt-text">A screenshot of my finished website.</p>
            <br />
            <p>
              <a href="https://genshin.hoyoverse.com/en/home">Here</a> is the
              link to download the game. It's free to play and cross-platform,
              so I would recommend trying it out!
            </p>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default App;
